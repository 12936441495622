import { FC } from 'react';
import './related-product.scss';
import { NJButton } from '@engie-group/fluid-design-system-react';
import { useWindowsSizeBlock } from '../../../../utils/hooks/use-windows-size-block';

interface RelatedProductProps {
  relatedProduct: {
    title: string;
    description?: string | null;
    button?: {
      label_desktop: string;
      label_mobile: string;
      url: string;
    } | null;
  };
}

const RelatedProduct: FC<RelatedProductProps> = ({ relatedProduct }) => {
  const { width } = useWindowsSizeBlock();
  const buttonLabel =
    width >= 1920
      ? relatedProduct.button?.label_desktop
      : relatedProduct.button?.label_mobile;

  return (
    <div className="related-product">
      <h2>{relatedProduct.title}</h2>
      <p>{relatedProduct.description}</p>
      {relatedProduct.button && (
        <NJButton
          emphasis="subtle"
          size="large"
          icon="arrow_forward"
          label={buttonLabel}
          variant="inverse"
          href={relatedProduct.button.url}
          target="_blank"
          className="related-product__button"
        />
      )}
    </div>
  );
};

export { RelatedProduct };
