import { FC } from 'react';
import './title-and-rich-text-block.scss'
import { RichText } from '../../atoms/rich-text/rich-text';
import { TitleAndRichTextBlock } from '../../../../types/strapi/component/blocks/title-and-rich-text';

interface TitleAndRichTextBlockProps {
    tartblock: TitleAndRichTextBlock
}

const TitleAndRichTextDisplayBlock: FC<TitleAndRichTextBlockProps> = ({ tartblock }) => {
  return (
    <div className="pptart-container">
      <div className="pptart-layout">
        {tartblock.title && <div className="ago-sub-tera-title pptart-title">{tartblock.title}</div>}
        {tartblock.description && (
          <RichText className={'ago-text-regular-16 pptart-text'} text={tartblock.description} />
        )}
      </div>
    </div>
  );
};

export { TitleAndRichTextDisplayBlock };
