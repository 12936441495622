import { array, number, object, string } from 'idonttrustlikethat';
import { strapiFileResponse } from '../file/strapi-file';

const contactAttributes = object({
  avatar: strapiFileResponse,
  email: string.nullable(),
  firstName: string,
  lastName: string,
  role: string,
});

const contact = object({
  id: number,
  attributes: contactAttributes,
});

const contacts = array(contact);
const contactResponse = object({
  data: contact,
  meta: object({}).optional(),
});

const contactsResponse = object({
  data: contacts.nullable(),
  meta: object({}).default({}),
});

type Contact = typeof contact.T;
type Contacts = typeof contacts.T;
type ContactResponse = typeof contactResponse.T;
type ContactsResponse = typeof contactsResponse.T;

export { contact, contacts, contactResponse, contactsResponse };
export type { Contact, Contacts, ContactResponse, ContactsResponse };
