import { DateTime } from 'luxon';

const dateFormat = (date: string, longFormat: boolean = false) => {
  return longFormat
    ? DateTime.fromISO(date).toLocaleString(DateTime.DATE_FULL)
    : DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT);
};

const isExpired = (endDate: string): boolean => {
  const currentDate = new Date();
  const eventEndDate = new Date(endDate);
  if (currentDate.getTime() > eventEndDate.getTime()) {
    return true;
  } else {
    return false;
  }
};

export { dateFormat, isExpired };
