import { FC } from 'react';
import './key-people-contact.scss';
import { NJButton } from '@engie-group/fluid-design-system-react';
import { Contact } from '../../../../types/strapi/contact/contact';
import { getStrapiImage, IMG_FORMATS } from '../../../../utils/image';

interface KeyPeopleContactProps {
  contact: Contact;
}

const capitalizeWords = (str: string) => {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const KeyPeopleContact: FC<KeyPeopleContactProps> = ({ contact }) => {
  const { avatar, email, firstName, lastName, role } = contact.attributes;

  return (
    <div className="key-people-card">
      <img
        src={getStrapiImage(avatar.data.attributes, IMG_FORMATS.large)}
        alt="key people avatar"
        className="key-people-card__avatar"
      />
      <h6 className="ago-principal-title-bold">{capitalizeWords(firstName + " " + lastName)}</h6>
      <p className="ago-text-regular-16">{capitalizeWords(role)}</p>
      {email && (
        <NJButton
          className="key-people-card__button"
          href={`mailto:${email}`}
          icon="mail_outline"
          label="Contact"
          emphasis="subtle"
          size="medium"
          variant="inverse"
        />
      )}
    </div>
  );
};

export { KeyPeopleContact };
