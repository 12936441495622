import { FC } from 'react';
import { NJButton, NJIcon } from '@engie-group/fluid-design-system-react';
import './event-action-button.scss';
import { ReactComponent as Outlook } from '../../../../images/svg/logos/outlook.svg';

interface EventActionButtonProps {
  joinType: string | undefined;
  joinUrl: string | undefined;
  reverse?: boolean;
  className?: string;
}

const EventActionButton: FC<EventActionButtonProps> = ({
  joinType,
  joinUrl,
  reverse,
  className,
}) => {
  return !joinType ? (
    <NJButton className={className} href={joinUrl} variant={reverse ? 'inverse' : 'primary'}>
      <NJIcon
        data-child-name="customIcon"
        name="open_in_browser"
        className={reverse ? 'eab-icon-reverse' : 'eab-icon'}
      />
      <span data-child-name="customLabel">Join now</span>
    </NJButton>
  ) : joinType === 'register' ? (
    <NJButton className={className} href={joinUrl} variant={reverse ? 'inverse' : 'primary'}>
      <NJIcon
        data-child-name="customIcon"
        name="border_color"
        className={reverse ? 'eab-icon-reverse' : 'eab-icon'}
      />
      <span data-child-name="customLabel">Register</span>
    </NJButton>
  ) : (
    <NJButton className={className} href={joinUrl} variant={reverse ? 'inverse' : 'primary'} disabled>
      <span
        data-child-name="customLabel"
        className={reverse ? 'eab-button-content eab-icon-reverse' : 'eab-button-content'}
      >
        <Outlook className="eab-icon-outlook" />
        Add to calendar
      </span>
    </NJButton>
  );
};

export { EventActionButton };
