import { FC } from 'react';
import "./return-to-top-button.scss"
import { NJIcon } from '@engie-group/fluid-design-system-react';

const ReturnToTopButton: FC = () => {
  return (
    <div className={`rttb-to-tp-button`}onClick={() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }}>
      <NJIcon name="expand_less" size="xxl" variant="inherit" />
    </div>
  );
};


export {ReturnToTopButton};