import { FC } from 'react';
import { RelatedProductsBlock } from '../../../../types/strapi/component/blocks/related-products';
import './related-products.scss';
import { RelatedProduct } from '../../molecules/related-product/related-product';

interface RelatedProductsBlockProps {
  relatedProductsBlock: RelatedProductsBlock;
}

const RelatedProductsDisplayBlock: FC<RelatedProductsBlockProps> = ({
  relatedProductsBlock,
}) => {
  return (
    <div className="related-products">
      <h1 className="ago-block-title">{relatedProductsBlock.title}</h1>
      <div className="related-products__list">
        {relatedProductsBlock.products.data.map((relatedProductBlock) => {
          return <RelatedProduct relatedProduct={relatedProductBlock.attributes} />;
        })}
      </div>
    </div>
  );
};

export { RelatedProductsDisplayBlock };
