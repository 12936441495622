import { array, isoDate, literal, number, object, string, url } from 'idonttrustlikethat';

const relatedProductButton = object({
  id: number,
  label_desktop: string,
  label_mobile: string,
  url: url,
});

const relatedProduct = object({
  id: number,
  attributes: object({
    title: string,
    description: string.nullable(),
    createdAt: isoDate,
    updatedAt: isoDate,
    publishedAt: isoDate,
    button: relatedProductButton.nullable(),
  }),
});

const relatedProducts = object({
  data: array(relatedProduct),
});

const relatedProductsComponent = literal('blocks.related-products');
const relatedProductsBlock = object({
  id: number,
  __component: relatedProductsComponent,
  title: string,
  products: relatedProducts,
});

type RelatedProductsBlock = typeof relatedProductsBlock.T;
type RelatedProductsComponent = typeof relatedProductsComponent.T;

export { relatedProductsBlock, relatedProductsComponent };
export type { RelatedProductsBlock, RelatedProductsComponent };
