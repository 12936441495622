import { literal, number, object, string } from 'idonttrustlikethat';

const titleAndRichTextComponent = literal('blocks.title-and-rich-text');
const titleAndRichTextBlock = object({
  id: number,
  __component: titleAndRichTextComponent,
  title: string.nullable(),
  description: string.nullable()
});

type TitleAndRichTextBlock = typeof titleAndRichTextBlock.T;
type TitleAndRichTextComponent = typeof titleAndRichTextComponent.T;

export { titleAndRichTextBlock, titleAndRichTextComponent };
export type { TitleAndRichTextBlock, TitleAndRichTextComponent };
